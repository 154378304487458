import axios from '@axios'

export default {
    namespaced: true,
    state: {
        faqs: [],
    },
    getters: {},
    mutations: {
        SET_FAQS(state, data) {
            state.faqs = data
        },
        SET_FAQ(state, item) {
            state.faqs = item
        },
        ADD_FAQ(state, item) {
            state.faqs.push(item)
        },
        EDIT_FAQ(state, item) {
            const index = state.faqs.findIndex(m => m._id === item._id)
            if (index >= 0) {
                Object.assign(state.faqs[index], item)
            }
        },
        REMOVE_FAQ(state, id) {
            const index = state.faqs.findIndex(m => m._id === id)
            if (index >= 0) {
                state.faqs.splice(index, 1)
            }
        },
    },
    actions: {
        getItems ({commit}, query = '') {
            return new Promise((resolve, reject) => {
                axios.get(`faqs?${query}`)
                    .then(response => {
                        commit("SET_FAQS", response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addItem ({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`faqs`, payload)
                    .then(response => {
                        commit("ADD_FAQ", response.data.item)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        editItem ({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`faqs/${payload._id}`, payload)
                    .then(response => {
                        commit("EDIT_FAQ", response.data.item)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteItem ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`faqs/${id}`)
                    .then(response => {
                        commit("REMOVE_FAQ", id)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    }
}

<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="menus" no-body>

    <div class="page-header">
      <div class="filters">
        <div class="filter-column" v-if="$checkPermission($options.name, 'insert')">
          <b-button @click="add" v-b-modal.modal-form variant="primary">Ավելացնել</b-button>
        </div>
      </div>
    </div>


    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="faqs"
        :fields="fields"
    >
      <template #cell(actions)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="changeStatus(data.item)"
                 icon="power-off"
                 :class="'table-action-icon item-status status-' + data.item.status"/>
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="edit(data.item)" icon="edit"
                 class="table-action-icon accent"/>
        <fa-icon v-if="$checkPermission($options.name, 'delete')" @click="deleteItem(data.item)" icon="trash-alt"
                 class="table-action-icon danger"/>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + totalRows + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- modal login-->
    <b-modal
        id="modal-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        :title="modal_title"
        :is-valid="false"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >
      <b-form>
        <b-form-group>
          <b-form-input
              v-model="form.title"
              id="name"
              type="text"
              placeholder="Հարց"
          />
        </b-form-group>
        <b-form-group>
          <quill-editor
              placeholder="Պատասխան"
              v-model="form.description"
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>

import FaqModule from '@/store/faq/faqModule'
import store from '@/store'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BFormTextarea
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'

export default {
  name: 'settings-faq',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    quillEditor
  },
  data() {
    return {
      FAQS_STORE_MODULE_NAME: 'faqModule',
      addForm: false,
      editForm: false,
      perPage: 20,
      currentPage: 1,
      fields: [
        {
          key: 'title',
          label: 'Անվանում',
        },
        {
          key: 'actions',
          label: 'Կարգավիճակ',
          class: 'text-center width-10-per px-0',
        },
      ],
      formFields: {
        title: '',
        description: '',
      },
      form: {}
    }
  },
  computed: {
    faqs() {
      return this.$store.state[this.FAQS_STORE_MODULE_NAME].faqs
    },
    totalRows() {
      return this.faqs.length
    },
    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    validateForm () {
      return this.form.title === '' || this.form.description === ''
    }
  },
  created() {
    if (!store.hasModule(this.FAQS_STORE_MODULE_NAME)) store.registerModule(this.FAQS_STORE_MODULE_NAME, FaqModule)
    this.getFaqs()
  },
  methods: {

    getFaqs() {
      this.$vs.loading()
      this.$store.dispatch(`${this.FAQS_STORE_MODULE_NAME}/getItems`)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
    },

    edit(item) {
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
      this.$bvModal.show('modal-form')
    },

    confirmForm() {
      if (this.addForm) {
        this.$vs.loading()
        this.$store.dispatch(this.FAQS_STORE_MODULE_NAME + '/addItem', this.form)
            .then(() => {
              this.$vs.loading.close()
            })
            .catch((error) => {
              this.$vs.loading.close()
              this.$root.$emit('getError', error)
            })
      } else {
        this.$store.dispatch(this.FAQS_STORE_MODULE_NAME + '/editItem', this.form)
            .then(() => {
              this.$vs.loading.close()
            })
            .catch((error) => {
              this.$vs.loading.close()
              this.$root.$emit('getError', error)
            })
      }
    },

    deleteItem(item) {
      this.$vs.loading()
      this.$store.dispatch(this.FAQS_STORE_MODULE_NAME + '/deleteItem', item._id)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    changeStatus(item) {
      item.status = !item.status
      this.$store.dispatch(this.FAQS_STORE_MODULE_NAME + '/editItem', item)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

  }
}
</script>

<style lang="scss">
.ql-editor {
  min-height: 200px;
}
</style>